<template>
  <div class="layout-service">

    <!--s: Service Menu-->
    <ServiceMenu v-if="!isPhone()" />
    <!--e: Service Menu-->

    <div class="layout-service-content">
      <div class="service-box">
        <h1 class="title" v-if="!isPhone()"></h1>
        <h1 class="title" v-if="isPhone()">
        {{ $t("footer.aboutUs") }}
        <a href="javascript:" class="close" @click="onClose"></a>
        </h1>
        <h2 class="subtitle">{{ $t("footer.aboutUs1") }}</h2>
        <div class="text2">{{ $t("footer.aboutUs2") }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import AboutUs from "./aboutUs";
export default AboutUs;
</script>
<style lang="less">
@import "aboutUs";
</style>
